import { CoreInterfaces, DTOs } from "src/core/Models";
import {
  formContactPersonItemFromBEEmployee,
  formEmployee,
  formServiceDTOsFromServiceConfigurationWrite,
} from "./dto";
import * as Constants from "./../core/Constants";
import { t } from "i18next";
import i18n from "src/i18n";
import { stringToISODate } from "./utils";

export function updateStateOnEngagementConfigurationRead(
  configurationPayload: CoreInterfaces.EngagementConfigurationReadPack,
  dispatchFn: Function,
  serviceHandlingFn: (serviceDTO: DTOs.ServiceDTO) => void = null,
  applicationConfiguration: CoreInterfaces.ApplicationConfigurationReadPack = null,
  scenario: Constants.UpdateStateOnEngagementConfigurationReadScenario = null
): void {
  if (!!configurationPayload) {
    const actionPayload: Partial<CoreInterfaces.AppState> = {
      currentConfiguration: {
        id: configurationPayload.Id,
        engagementLetterParentId:
          configurationPayload.EngagementLetterParentId ?? null,
        version: configurationPayload.Version,
        businessUnit: configurationPayload.BusinessUnit,
        lastModifiedDate: configurationPayload.LastModifiedDate,
        organisationNumber: configurationPayload.OrganisationNumber,
        companyName: configurationPayload.CompanyName,
        status: configurationPayload.Status,
        description: configurationPayload.Description,
        createdBy: configurationPayload.CreatedBy,
        createdDate: configurationPayload.CreatedDate,
        signingDate: configurationPayload.SigningDate,
        signingStatus: configurationPayload.SigningStatus,
        lastAccessedTime: configurationPayload.LastAccessedTime,
        applicationConfiguration: {
          rateCartConfiguration: {
            ...configurationPayload.RateCartConfiguration,
          },
          taskAllocationConfigurationVersion:
            configurationPayload.TaskAllocationConfigurationVersion,
          taskAllocationConfigurationDescription:
            configurationPayload.TaskAllocationConfigurationDescription || "",
          taskCostConfigurationVersion:
            configurationPayload.TaskCostConfigurationVersion,
          taskCostConfigurationDescription:
            configurationPayload.TaskCostConfigurationDescription || "",
        },
        consent: configurationPayload.Consent,
        digitalSigningDetails: configurationPayload.DigitalSigningDetails,
        totalCost: configurationPayload.TotalCost,
        totalCostManualOverride: configurationPayload.TotalCostManualOverride, 
        totalWorkingHours: configurationPayload.TotalWorkingHours,
        crmRowId: configurationPayload.CrmRowId,
        engagementLetterMetadata: null,
        owner: null,
        validFromDate: configurationPayload.ValidFromDate
          ? new Date(configurationPayload.ValidFromDate)
          : null,
        validUntilDate: configurationPayload.ValidUntilDate
          ? new Date(configurationPayload.ValidUntilDate)
          : null,
        isHiddenFromImporting: configurationPayload.IsHiddenFromImporting,
        engagementDocumentsLanguage: configurationPayload.Language,
        lastUserChangesDate: configurationPayload.LastUserChangesDate
          ? new Date(configurationPayload.LastUserChangesDate)
          : null,
      },
    };

    if (configurationPayload.EngagementLetterProperties) {
      actionPayload.currentConfiguration.engagementLetterMetadata = {
        createdBy: null,
        createdDate: null,
      };
      if (configurationPayload.EngagementLetterProperties.CreatedDate) {
        actionPayload.currentConfiguration.engagementLetterMetadata.createdDate =
          stringToISODate(
            configurationPayload.EngagementLetterProperties.CreatedDate
          );
      }
      if (configurationPayload.EngagementLetterProperties.CreatedBy) {
        actionPayload.currentConfiguration.engagementLetterMetadata.createdBy =
          formEmployee(
            configurationPayload.EngagementLetterProperties.CreatedBy
          );
      }
    }
    if (configurationPayload.Owner) {
      actionPayload.currentConfiguration.owner =
        formContactPersonItemFromBEEmployee(configurationPayload.Owner);
    }
    if (!!applicationConfiguration) {
      if (!!applicationConfiguration?.RateCartConfiguration?.Description) {
        actionPayload.currentConfiguration.applicationConfiguration.rateCartConfiguration.Description =
          applicationConfiguration?.RateCartConfiguration?.Description;
      }
      if (
        !!applicationConfiguration?.TaskAllocationConfiguration?.Description
      ) {
        actionPayload.currentConfiguration.applicationConfiguration.taskAllocationConfigurationDescription =
          applicationConfiguration?.TaskAllocationConfiguration.Description;
      }
      if (!!applicationConfiguration?.TaskCostConfiguration?.Description) {
        actionPayload.currentConfiguration.applicationConfiguration.taskCostConfigurationDescription =
          applicationConfiguration?.TaskCostConfiguration.Description;
      }
    }
    if (!!serviceHandlingFn) {
      const serviceDTOs = formServiceDTOsFromServiceConfigurationWrite(
        configurationPayload,
        applicationConfiguration,
        serviceHandlingFn
      );
      actionPayload.services = serviceDTOs;
    }

    const { ImportConfiguration, LoadConfiguration, Conflict } =
      Constants.UpdateStateOnEngagementConfigurationReadScenario;
    if ([LoadConfiguration, ImportConfiguration, Conflict].includes(scenario)) {
      dispatchFn({
        type: Constants.AppStateActions.UpdateStateWithImportedConfiguration,
        payload: actionPayload,
      });
    } else {
      dispatchFn({
        type: Constants.AppStateActions.UpdateStateWithSavedConfiguration,
        payload: actionPayload,
      });
    }
  } else {
    const currentStoragedLng = localStorage.getItem(Constants.i18nStorageKey);
    i18n.changeLanguage(Constants.Languages.SE.toLocaleLowerCase(), () => {
      // i18n.changeLanguage() -> change automatically localstorage key to SE
      // reset language in local-storage in order to keep previously selected language for already saved engagements
      localStorage.setItem(Constants.i18nStorageKey, currentStoragedLng);
    });
    dispatchFn({
      type: Constants.AppStateActions.UpdateApplicationLanguage,
      payload: {
        language: Constants.Languages.SE,
      },
    });
  }
}

export function findQuestionInService(
  serviceDTO: DTOs.ServiceDTO,
  questionCode: Constants.QuestionCode
): DTOs.QuestionDTO {
  return serviceDTO.data.questions.find(
    (eachQuestion) => eachQuestion.data.code === questionCode
  );
}

//Panels
export function resolveTaskDisplayedComment(
  taskDTO: DTOs.TaskDTO,
  params?: object
): string {
  return (
    taskDTO.data.adjustments.comment ??
    (taskDTO.data.type === Constants.TaskType.Automatic
      ? t(taskDTO.data.comment, { ...params })
      : taskDTO.data.comment)
  );
}

export function resolveTaskDisplayedAllocationOfResponsibility(
  taskDTO: DTOs.TaskDTO,
  configValue?: string | number
): Constants.TaskAllocationResponsibility {
  return (
    taskDTO.data.adjustments.allocationOfResponsibility ??
    taskDTO.data.allocationOfResponsibility ??
    (configValue as Constants.TaskAllocationResponsibility)
  );
}

export function resolveTaskDisplayedFrequency(
  taskDTO: DTOs.TaskDTO,
  configValue: string | number
): Constants.Frequency {
  return (
    taskDTO.data.adjustments.frequency ??
    taskDTO.data.frequency ??
    (configValue as Constants.Frequency)
  );
}

export function isRevertDisabled(params: any, fieldName: string) {
  return (
    params.row.taskDTO?.data?.adjustments[fieldName] === null ||
    !params.row.taskDTO?.state?.isActive ||
    params.row.taskDTO.data[fieldName] ===
      params.row.taskDTO?.data?.adjustments[fieldName]
  );
}

export function resolveContactPerson(contactPersonValue: string) {
  return contactPersonValue !==
    `Options.${Constants.HelpfulConstants.PleaseSelect}`
    ? contactPersonValue
    : "";
}

export function resolveContactPersonTestUtils(contactPersonValue: string) {
  return contactPersonValue;
}

export function isEngagementInFinalVersion(
  globalState: CoreInterfaces.AppState
): boolean {
  return (
    globalState.currentConfiguration.status ===
    Constants.ConfigurationStatus.FinalVersion
  );
}

export const mapUserResponseToCustomerContactPersons = (
  globalState: CoreInterfaces.AppState,
  questionDTO: DTOs.QuestionDTO
): Array<CoreInterfaces.ContactPersonItem> => {
  let contactPersons: Array<CoreInterfaces.ContactPersonItem> = [];
  if (Array.isArray(questionDTO.data.userValue)) {
    contactPersons = questionDTO.data.userValue.map((eachItem) => {
      const contactPerson = globalState.remoteData.customerContactPersons.find(
        (eachCustomerContactPerson) => {
          return eachCustomerContactPerson.key == eachItem.toString();
        }
      );
      return contactPerson;
    });
  }

  return contactPersons;
};

export const contactPersonWithEmailMapper = (
  contactPersonItem: CoreInterfaces.ContactPersonItem
) =>
  `${contactPersonItem.firstName} ${contactPersonItem.lastName}, ${contactPersonItem.email}`;
export const contactPersonMapper = (
  contactPersonItem: CoreInterfaces.ContactPersonItem
) => `${contactPersonItem.firstName} ${contactPersonItem.lastName}`;

export const formatOrganisationNo = (organisationNumber: string) => {
  if (organisationNumber.length === 10) {
    return organisationNumber.replace(/(\d{6})(\d{4})/, "$1-$2");
  } else {
    return organisationNumber;
  }
};

// API
export const API_BASE_URL = "/";

export enum APIPath {
  EngagementConfiguration = "api/engagementConfiguration",
  EngagementConfigurationNewVersion = "api/engagementConfiguration/newVersion",
  ApplicationConfiguration = "api/applicationConfiguration",
  ContactPersonsForGeneralInfo = "api/company/{upsalescustomerid}/contactpersons",
  Employees = "api/employees",
  EmployeesOffices = "api/employees/offices",
  CompanyDetails = "api/company/{upsalescustomerid}",
  AuditLogs = "api/audit",
  EngagementVersions = "api/engagementVersions",
  RateCart = "api/engagementConfiguration/ratecart",
  BusinessOpportunityInfo = "api/opportunityinfo",
  FinalVersion = "api/engagementConfiguration/finalVersion",
  FinalVersionForWonOportunities = "api/engagementConfiguration/finalVersionForWonOportunities",
  ManuallySign = "api/engagementConfiguration/manuallySign",
  SendForDigitalSigning = "api/engagementConfiguration/sendForDigitalSigning",
  CompaniesWithEngagements = "api/engagementConfiguration/companiesWithEngagements",
  EngagementsForCompany = "api/engagementConfiguration/engagementsforcompany",
  NotificationMessages = "api/notification-messages",
  ConvertToPdf = "api/ConvertToPdf",
  HideFromImporting = "api/engagementConfiguration/setEngagementVersionHiddenFromImport",
  EngagementDocumentGetAll = "api/engagementConfigurationDocument/{engagementConfigurationId}/alldocuments",
  EngagementDocumentContent = "api/engagementConfigurationDocument/{engagementConfigurationId}/documents/{documentId}",
  EngagementDocumentsUpload = "api/engagementConfigurationDocument/{businessOpportunityId}/{engagementConfigurationId}/documents",
  EngagementDocumentDelete = "api/engagementLetter/{businessOpportunityId}/{engagementConfigurationId}/additionalDocuments/{documentId}",
  OfficeManagers = "api/officeManagers",
  SearchEngagementLetters = "api/engagementConfiguration/search",
  SetOwner = "api/engagementConfiguration/setOwner",
  SetEngagementVersionActivationState = "api/engagementConfiguration/setEngagementVersionActivationState",
  UserRoles = "api/userRoles",
  Maintenance = "api/maintenanceMode",
}

export enum AadApiPath {
  AuthLogin = "/.auth/login/aad",
  AuthLogout = "/.auth/logout",
  AuthMe = ".auth/me",
}

export enum Claims {
  Firstname = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname",
  Lastname = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
  Email = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
}

export enum APIMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum HttpStatusCodes {
  Locked = 423,
}

//user roles
export enum UserRoles {
  Administrator = "Administrator",
  OfficeManager = "OfficeManager",
  User = "User",
}

// state
export enum AppStateActions {
  ServicesOverride = "SERVICES_OVERRIDE",
  ServiceUpdated = "SERVICE_UPDATED",
  UpdateServiceStaffingAdjustmentCost = "UPDATE_SERVICE_STAFFING_ADJUSTMENT_COST",
  UpdateServiceStaffingAdjustmentExtendedCost = "UPDATE_SERVICE_STAFFING_ADJUSTMENT_EXTENDED_COST",
  ServiceExpandChange = "SERVICE_EXPAND_CHANGE",
  QuestionUpdated = "QUESTION_UPDATED",
  TaskAdd = "TASK_ADD",
  TaskDelete = "TASK_DELETE",
  TaskUpdated = "TASK_UPDATED",
  TaskOrderChange = "TASK_ORDER_CHANGE",
  LoadingBlurredStateUpdate = "LOADING_BLURRED_STATE_UPDATE",
  UpdateStateWithSavedConfiguration = "UPDATE_STATE_WITH_SAVED_CONFIGURATION",
  UpdateStateWithImportedConfiguration = "UPDATE_STATE_WITH_IMPORTED_CONFIGURATION",
  UpdateRemoteData = "UPDATE_REMOTE_DATA",
  UpdateApplicationConfiguration = "UPDATE_APPLICATION_CONFIGURATION",
  UpdateAadUserInfo = "UPDATE_AAD_USER_INFO",
  UpdateRateCartsConfiguration = "UPDATE_RATE_CARTS",
  UpdateConsent = "UPDATE_CONSENT",
  UpdateApplicationLanguage = "UPDATE_APPLICATION_LANGUAGE",
  UpdateHiddenFromImportingState = "UPDATE_HIDDEN_FROM_IMPORTING_STATE",
  HideSystemAdministrationMessage = "HIDE_SYSTEM_ADMINISTRATION_MESSAGE",
  ApplicationConfigurationVersionsChanged = "APPLICATION_CONFIGURATION_VERSIONS_CHANGED",
  RatesChangeMessageVisibilityUpdated = "RATES_CHANGE_MESSAGE_VISIBILITY_UPDATED",
  ValidFromDateChanged = "VALID_FROM_DATE_CHANGED",
  ChangeUnsavedFlag = "CHANGE_UNSAVED_FLAG",
  ServiceAdditionalCostAdjustementChange = "SERVICE_ADDITIONAL_COST_ADJUSTEMENT_CHANGE",
  UpdateEngagementConflict = "UPDATE_ENGAGEMENT_CONFLICT",
  UpdateLicenseValue = "UPDATE_LICENSE_VALUE",
  AddNewLicense = "LICENSE_ADD",
  DeleteLicense = "LICENSE_DELETE",
  UpdateEngagementDocumentsMetadata = "UPDATE_ENGAGEMENT_DOCUMENTS_METADATA",
  AddContactPerson = "ADD_CONTACT_PERSON",
  DeleteContactPerson = "DELETE_CONTACT_PERSON",
  UpdateContactPersonValue = "UPDATE_CONTACT_PERSON_VALUE",
  UpdateRangeNumberQuestion = "UPDATE_RANGE_NUMBER_QUESTION",
  UpdateTotalCostManualOverride = "UPDATE_TOTAL_COST_MANUAL_OVERRIDE"
}

// other
export enum AuditLogActionTypes {
  Access = "AccessConfig",
  Create = "CreateConfig",
  Delete = "DeleteConfig",
  ChangeRate = "ChangeRate",
  CheckApprovalQuestion = "CheckApprovalQuestion",
  SaveAlternativeConfig = "SaveAlternativeConfig",
  DocumentSentForDigitalSigning = "DocumentSentForDigitalSigning",
  DocumentSetToSignedManually = "DocumentSetToSignedManually",
  FinalVersionCreated = "FinalVersionCreated",
  GenerateDocument = "GenerateDocument",
}

export enum ValidityStatus {
  Valid = "Valid",
  ToExpire = "ToExpire",
  Expired = "Expired",
}

export enum Languages {
  EN = "EN",
  SE = "SE",
}

export const i18nStorageKey = "i18nextLng";
export const i18nDocumentStorageKey = "i18DocumentLng";

export enum AlertSeverity {
  Error = "error",
  Info = "info",
  Success = "success",
}

export enum GtAlertBannerVariant {
  GenerateDocuments = "generateDocuments",
}

export enum TaskVisibilityArea {
  Engagement = "Engagement",
  Calculation = "Calculation",
}

export enum TaskType {
  Automatic = "Automatic",
  ManuallyAdded = "ManuallyAdded",
}

export enum HelpfulConstants {
  DateFormatYearAndMonth = "yyyy-MM",
  DateFormatYearMonthAndDay = "yyyy-MM-dd",
  DateFormatYearMonthAndDayUpperCase = "YYYY-MM-DD",
  DateFormatDayMonthYear = "DD-MM-YYYY",
  DateFormatYearMonthDayHourMinutes = "yyyy-MM-dd, HH:mm",
  DefaultAccountingSystem = "Fortnox",
  EmptyString = "",
  Flow = "FLOW",
  NotApplicable = "NotApplicable",
  OneEmployee = "1",
  PayrollNumberOfEmployees = "10",
  PleaseSelect = "PleaseSelect",
  PricePerMonth = "LicensePricePerMonth",
}

export enum Frequency {
  Daily = "Daily",
  OnceAWeek = "OnceAWeek",
  TwiceAWeek = "TwiceAWeek",
  EverySecondWeek = "EverySecondWeek",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
  WhenNeeded = "WhenNeeded",
  OneTime = "OneTime",
}

export enum VATDeclarationFrequency {
  MonthlyOver = "MonthlyOver",
  MonthlyUnder = "MonthlyUnder",
}

export enum SystemAdministrationMessageTypes {
  Information = "Information",
  Alert = "Alert",
}

export const FrequencyValueMap: { [frequency in Frequency]?: number } = {
  [Frequency.Daily]: 21,
  [Frequency.OnceAWeek]: 4.1,
  [Frequency.TwiceAWeek]: 8.7,
  [Frequency.EverySecondWeek]: 2.2,
  [Frequency.Monthly]: 1,
  [Frequency.Quarterly]: 0.33,
  [Frequency.Yearly]: 0.08333,
  [Frequency.WhenNeeded]: 0,
};

export enum InvoicingTime {
  InAdvance = "InAdvance",
  InArears = "InArears",
  Ongoing = "Ongoing",
  Other = "OtherComment",
}

export enum TaskCalculationMethod {
  AmountXTimeXPricePerHour = "AmountXTimeXPricePerHour", // A
  FrequencyXTimeXPricePerHour = "FrequencyXTimeXPricePerHour", // B
  TimeXPricePerHour = "TimeXPricePerHour", // C
  AmountXTime = "AmountXTime", // D
  AmountXPrice = "AmountXPrice", // E
  FrequencyXAmountXTime = "FrequencyXAmountXTime", // F
}

export enum QuestionInputType {
  Readonly = "readonly",
  Text = "text",
  Multiline = "multiline",
  Select = "select",
  Autocomplete = "autocomplete",
  YesNo = "yesNo",
  Numeric = "numeric",
  Date = "date",
  Currency = "currency",
  Headline = "headline",
  Checkbox = "checkbox",
  License = "license",
  FullDate = "fullDate",
  ContactPersonUserDetails = "contactPersonUserDetails",
  MultipleSelect = "multipleSelect",
  RangeNumber = "rangeNumber",
}

export enum TaskAllocationResponsibility {
  Client = "Client",
  GT = "GT",
}

export enum YesNo {
  Yes = "Yes",
  No = "No",
}

export enum SigningStatus {
  NotSigned = "NotSigned",
  SentForDigitallySigned = "SentForDigitallySigned",
  DigitallySigned = "DigitallySigned",
  DigitallySignedDeclined = "DigitallySignedDeclined",
  ManuallySigned = "ManuallySigned",
  SignedObsolete = "SignedObsolete",
}

export enum CustomerBank {
  Handelsbanken = "Handelsbanken",
  SEB = "SEB",
  Nordea = "Nordea",
  Swedbank = "Swedbank",
  Lansforsakringar = "Lansforsakringar",
  DanskeBank = "DanskeBank",
  Otherbanks = "OtherBanks",
}

export enum StaffLevel {
  Consultant = "Consultant",
  ConsultantPayroll = "ConsultantPayroll",
  SeniorConsultant = "SeniorConsultant",
  SeniorConsultantPayroll = "SeniorConsultantPayroll",
  AssistantManager = "AssistantManager",
  AssistantManagerPayroll = "AssistantManagerPayroll",
  Manager = "Manager",
  SeniorManager = "SeniorManager",
  ManagerPayroll = "ManagerPayroll",
  SeniorManagerPayroll = "SeniorManagerPayroll",
  Specialist = "Specialist",
  SeniorSpecialist = "SeniorSpecialist",
}

export enum AccountingMethod {
  CashFlow = "CashFlow",
  Accrual = "Accrual",
}

export enum AccountPayablePreSystem {
  FortnoxLeverantorsfakturaattest = "PreSystemFortnox",
  MediusGo = "PreSystemMediusGo",
  AnnatForsystem = "PreSystemAnnat",
}

export enum AccountingStandards {
  IFRS = "IFRS",
  K3 = "K3",
}

export enum CorporateCardPreSystem {
  KvittoResa = "PreSystemKvittoResa",
  Flex = "PreSystemFlex",
  AnnatForsystem = "PreSystemAnnat",
}

export enum CardProvider {
  Citibank = "Citibank",
  DanskeBankForetagskort = "DanskeBankForetagskort",
  DNB = "DNB",
  Eurocard = "Eurocard",
  FirstCard = "FirstCard",
  Handelsbanken = "Handelsbanken",
  Mynt = "Mynt",
  Other = "Other",
  SEBForetagskort = "SEBForetagskort",
}

export enum CollectiveAgreement {
  NoAgreement = "AgreementNone",
  Teknikavtalet = "AgreementTeknikavtalet",
  Motorbranchavtalet = "AgreementMotorbranchavtalet",
  Detaljhandelsavtalet = "AgreementDetaljhandelsavtalet",
  Lager = "AgreementLager",
  Other = "Other",
}

export enum CompanyReport {
  K2 = "CompanyK2",
  K3 = "CompanyK3",
}

export enum FeeExpression {
  Amount = "Amount",
  Interval = "Interval",
  IntervalFee = "IntervalFee",
  OnCurrentAccount = "OnCurrentAccount",
  Table = "Table",
  Current = "Current",
  Period = "Period",
  PeriodFee = "PeriodFee",
}

export enum PartOfAnnualAccounts {
  AnnualAccounts = "AnnualAccounts",
  AnnualReport = "AnnualReport",
  CompanyDeclaration = "CompanyDeclaration",
}
export enum PayslipDistribution {
  MyPaySlip = "PaysLips-MyPaySlip",
  Kivra = "PaylipsKivra",
  Flex = "PaylipsFlex",
  Fortnox = "PaylipsFortnox",
}

export enum PeriodReportMethod {
  BudgetRapport = "BudgetRapport",
  Other = "Other",
}

export enum PriceList {
  PriceList1 = "PriceList1",
  PriceList2 = "PriceList2",
  PriceList3 = "PriceList3",
  PriceList4 = "PriceList4",
}

export enum PriceListDataType {
  FixedPrice = "FixedPrice",
  DynamicPrice = "DynamicPrice", // a better name would make sense here.
}

export enum ResponsibleForInterimReport {
  GT = "GT",
  GTAndClient = "GTAndClient",
  Client = "InterimReportClient",
}

export enum ServiceCode {
  GeneralInformation = "GeneralInformation",
  AccountsReceivable = "AccountsReceivable",
  AccountsPayable = "AccountsPayable",
  CorporateCardManagement = "CorporateCardManagement",
  OtherAccountAndReconciliation = "OtherAccountAndReconciliation",
  PeriodReporting = "PeriodReporting",
  InterimListedCompanies = "InterimListedCompanies",
  AnnualReporting = "AnnualReporting",
  AnnualReportingListedCompanies = "AnnualReportingListedCompanies",
  PayrollAndExpenseAndTravelInvoiceManagement = "PayrollAndExpenseAndTravelInvoiceManagement",
  AnnualPayrollRoutines = "AnnualPayrollRoutines",
  StartUpAccounting = "StartUpAccounting",
  StartUpPayroll = "StartUpPayroll",
  YearlyInternalDocumentationAndFormalities = "YearlyInternalDocumentationAndFormalities",
  EngagementCounseling = "EngagementCounseling",
}

export enum ServiceRecurringType {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
  OneTime = "OneTime",
}

export enum ServiceGroup {
  Accounting = "Accounting",
  Payroll = "Payroll",
}

export enum TaskGroup {
  DefaultGroup = "ServiceGroups.Default",
  //service6
  AnnualReport = "ServiceGroups.AnnualReport",
  AnnualReportV2 = "ServiceGroups.AnnualReportV2",
  AnnualReportV3 = "ServiceGroups.AnnualReportV3",
  CorporateIncomeTax = "ServiceGroups.CorporateIncomeTax",
  StatutoryAnnualReport = "ServiceGroups.StatutoryAnnualReport",
  //service7
  MonthlyPayrollRun = "ServiceGroups.MonthlyPayrollRun",
  MonthlyPayrollReconciliation = "ServiceGroups.MonthlyPayrollReconciliation",
  PayrollServicesInAddition = "ServiceGroups.PayrollServicesInAdition",
  MonthlyReportingPayroll = "ServiceGroups.MonthlyReportingPayroll",
  AdditionalPayrollServices = "ServiceGroups.AdditionalPayrollServices",
}

export enum ConfigurationStatus {
  Active = "Active",
  FinalVersion = "FinalVersion",
  OutdatedVersion = "OutdatedVersion",
}

export enum TaskStatuses {
  Disable = "Disable",
  Enable = "Enable",
}

export enum TaskActions {
  Disable = "Disable",
  Enable = "Enable",
  Delete = "Delete",
}

export enum LetterSummaryActions {
  Activate = "Activate",
  Deactivate = "Deactivate",
}

export enum VacationYear {
  CurrentVacation = "CurrentVacation",
  PreviousVacation = "PreviousVacation",
  Other = "Other",
}

export enum VacationPay {
  Continuously = "Continuously",
  InConnectionWithVacation = "InConnectionWithVacation",
}

export enum PayrollSystems {
  Fortnox = "Fortnox",
  HogiaLonPlus = "HogiaLonPlus",
}

export enum Environments {
  DEV = "DEV",
  TEST = "TEST",
  STAGE = "STAGE",
}

export enum BusinessOpportunityStatuses {
  WON = "Won",
  OPEN = "Open",
}

export enum LicenseProperties {
  LicenseName = "LicenseName",
  NumberOfUnits = "NumberOfUnits",
  PricePerMonth = "PricePerMonth",
}

export enum Declaration {
  INK1 = "INK1",
  INK1AndK10 = "INK1AndK10",
  K10 = "K10",
}

export enum CounselingAssignment {
  Analysis = "Analysis",
  Economics = "Economics",
  Other = "Other",
}

export enum GeneralInformationQuestion {
  Q0001 = "Q0001",
  Q0002 = "Q0002",
  Q0003 = "Q0003",
  Q0004 = "Q0004",
  Q0005 = "Q0005",
  Q0006 = "Q0006",
  Q0007 = "Q0007",
  Q0008 = "Q0008",
  Q0009 = "Q0009",
  Q0010 = "Q0010",
  Q0011 = "Q0011",
  Q0012 = "Q0012",
  Q0013 = "Q0013",
  Q0014 = "Q0014",
  Q0015 = "Q0015",
  Q0016 = "Q0016",
  Q0017 = "Q0017",
  Q0018 = "Q0018",
  Q0019 = "Q0019",
  Q0020 = "Q0020",
  Q0021 = "Q0021",
  Q0022 = "Q0022",
  Q0023 = "Q0023",
  Q0024 = "Q0024",
  Q0025 = "Q0025",
  Q0026 = "Q0026",
  Q0027 = "Q0027",
  Q0028 = "Q0028",
  Q0029 = "Q0029",
  Q0030 = "Q0030",
  Q0031 = "Q0031",
  Q0032 = "Q0032",
  Q0033 = "Q0033",
  Q0034 = "Q0034",
  Q0035 = "Q0035",
  Q0036 = "Q0036",
  Q0037 = "Q0037",
  Q0038 = "Q0038",
  Q0039 = "Q0039",
  Q0040 = "Q0040",
  Q0041 = "Q0041",
  Q0042 = "Q0042",
  Q0043 = "Q0043",
}

export enum AccountReceivableQuestion {
  Q0101 = "Q0101",
  Q0102 = "Q0102",
  Q0103 = "Q0103",
  Q0104 = "Q0104",
  Q0105 = "Q0105",
  Q0106 = "Q0106",
  Q0107 = "Q0107",
  Q0108 = "Q0108",
  Q0109 = "Q0109",
  Q0110 = "Q0110",
  Q0111 = "Q0111",
  Q0112 = "Q0112",
  Q0113 = "Q0113",
  Q0114 = "Q0114",
  Q0115 = "Q0115",
  Q0116 = "Q0116",
  Q0117 = "Q0117",
  Q0118 = "Q0118",
  Q0119 = "Q0119",
  Q0120 = "Q0120",
  Q0121 = "Q0121",
  Q0122 = "Q0122",
}

export enum AccountPayableQuestion {
  Q0201 = "Q0201",
  Q0202 = "Q0202",
  Q0203 = "Q0203",
  Q0204 = "Q0204",
  Q0205 = "Q0205",
  Q0206 = "Q0206",
  Q0205_1 = "Q0205_1",
  Q0206_1 = "Q0206_1",
  Q0207 = "Q0207",
  Q0208 = "Q0208",
  Q0209 = "Q0209",
  Q0210 = "Q0210",
  Q0213 = "Q0213",
  Q0214 = "Q0214",
  Q0215 = "Q0215",
  Q0216 = "Q0216",
  Q0217 = "Q0217",
  Q0218 = "Q0218",
  Q0219 = "Q0219",
  Q0220 = "Q0220",
  Q0221 = "Q0221",
  Q0222 = "Q0222",
  Q0223 = "Q0223",
  Q0224 = "Q0224",
  Q0225 = "Q0225",
  Q0226 = "Q0226",
  Q0227 = "Q0227",
}

export enum CorporateCardManagementQuestion {
  Q0301 = "Q0301",
  Q0302 = "Q0302",
  Q0303 = "Q0303",
  Q0303_1 = "Q0303_1",
  Q0304 = "Q0304",
  Q0305 = "Q0305",
  Q0306 = "Q0306",
  Q0307 = "Q0307",
  Q0308 = "Q0308",
  Q0309 = "Q0309",
  Q0310 = "Q0310",
  Q0311 = "Q0311",
  Q0312 = "Q0312",
  Q0313 = "Q0313",
  Q0314 = "Q0314",
}

export enum OtherAccountAndReconciliationQuestions {
  Q0401 = "Q0401",
  Q0402 = "Q0402",
  Q0403 = "Q0403",
  Q0404 = "Q0404",
  Q0405 = "Q0405",
  Q0406 = "Q0406",
  Q0407 = "Q0407",
  Q0408 = "Q0408",
  Q0409 = "Q0409",
  Q0410 = "Q0410",
  Q0411 = "Q0411",
  Q0412 = "Q0412",
  Q0413 = "Q0413",
  Q0414 = "Q0414",
  Q0415 = "Q0415",
  Q0416 = "Q0416",
  Q0417 = "Q0417",
}

export enum PeriodReportingQuestion {
  Q0501 = "Q0501",
  Q0502 = "Q0502",
  Q0503 = "Q0503",
  Q0504 = "Q0504",
  Q0505 = "Q0505",
  Q0506 = "Q0506",
  Q0507 = "Q0507",
  Q0508 = "Q0508",
  Q0509 = "Q0509",
  Q0509_1 = "Q0509_1",
  Q0510 = "Q0510",
  Q0511 = "Q0511",
  Q0512 = "Q0512",
  Q0513 = "Q0513",
  Q0514 = "Q0514",
}

export enum InterimListedCompaniesQuestions {
  Q0551 = "Q0551",
  Q0552 = "Q0552",
  Q0553 = "Q0553",
}

export enum AnnualReportingQuestions {
  Q0601 = "Q0601",
  Q0602 = "Q0602",
  Q0603 = "Q0603",
  Q0604 = "Q0604",
  Q0605 = "Q0605",
  Q0606 = "Q0606",
  Q0607 = "Q0607",
  Q0608 = "Q0608",
  Q0609 = "Q0609",
  Q0610 = "Q0610",
  Q0611 = "Q0611",
  Q0612 = "Q0612",
  Q0613 = "Q0613",
  Q0614 = "Q0614",
  Q0615 = "Q0615",
  Q0616 = "Q0616",
  Q0617 = "Q0617",
  Q0618 = "Q0618",
  Q0619 = "Q0619",
  Q0620 = "Q0620",
  Q0621 = "Q0621",
  Q0622 = "Q0622",
  Q0623 = "Q0623",
  Q0624 = "Q0624",
  Q0625 = "Q0625",
  Q0626 = "Q0626",
  Q0627 = "Q0627",
  Q0628 = "Q0628",
  Q0629 = "Q0629",
  Q0630 = "Q0630",
  Q0631 = "Q0631",
  Q0632 = "Q0632",
  Q0633 = "Q0633",
  Q0634 = "Q0634",
  Q0635 = "Q0635",
  Q0636 = "Q0636",
  Q0637 = "Q0637",
  Q0638 = "Q0638",
  Q0639 = "Q0639",
  Q0640 = "Q0640",
  Q0641 = "Q0641",
  Q0642 = "Q0642",
}
export enum AnnualReportingListedCompaniesQuestions {
  Q0651 = "Q0651",
  Q0652 = "Q0652",
  Q0653 = "Q0653",
}

export enum PayrollAndExpenseAndTravelInvoiceManagementQuestions {
  Q0701 = "Q0701",
  Q0702 = "Q0702",
  Q0703 = "Q0703",
  Q0704 = "Q0704",
  Q0705 = "Q0705",
  Q0706 = "Q0706",
  Q0707 = "Q0707",
  Q0708 = "Q0708",
  Q0709 = "Q0709",
  Q0710 = "Q0710",
  Q0711 = "Q0711",
  Q0712 = "Q0712",
  Q0713 = "Q0713",
  Q0714 = "Q0714",
  Q0715 = "Q0715",
  Q0716 = "Q0716",
  Q0717 = "Q0717",
  Q0718 = "Q0718",
  Q0719 = "Q0719",
  Q0720 = "Q0720",
  Q0721 = "Q0721",
  Q0722 = "Q0722",
  Q0723 = "Q0723",
  Q0724 = "Q0724",
  Q0725 = "Q0725",
  Q0726 = "Q0726",
  Q0727 = "Q0727",
  Q0728 = "Q0728",
  Q0729 = "Q0729",
  Q0730 = "Q0730",
  Q0731 = "Q0731",
  Q0732 = "Q0732",
  Q0733 = "Q0733",
  Q0734 = "Q0734",
  Q0735 = "Q0735",
  Q0736 = "Q0736",
  Q0740 = "Q0740",
  Q0741 = "Q0741",
  Q0744 = "Q0744",
  Q0745 = "Q0745",
  Q0746 = "Q0746",
  Q0748 = "Q0748",
  Q0749 = "Q0749",
  Q0750 = "Q0750",
  Q0751 = "Q0751",
  Q0752 = "Q0752",
  Q0753 = "Q0753",
  Q0754 = "Q0754",
  Q0755 = "Q0755",
  Q0756 = "Q0756",
  Q0757 = "Q0757",
  Q0758 = "Q0758",
  Q0759 = "Q0759",
  Q0760 = "Q0760",
  Q0761 = "Q0761",
  Q0762 = "Q0762",
  Q0763 = "Q0763",
  Q0764 = "Q0764",
}

export enum StartUpAccountingQuestions {
  Q0801 = "Q0801",
  Q0802 = "Q0802",
  Q0803 = "Q0803",
  Q0804 = "Q0804",
  Q0805 = "Q0805",
}

export enum StartUpPayrollQuestions {
  Q0901 = "Q0901",
  Q0902 = "Q0902",
}

export enum AccountReceivableTasks {
  T0101 = "T0101",
  T0102 = "T0102",
  T0103 = "T0103",
  T0104 = "T0104",
  T0105 = "T0105",
  T0106 = "T0106",
  T0107 = "T0107",
  T0108 = "T0108",
  T0109 = "T0109",
  T0110 = "T0110",
  T0111 = "T0111",
  T0112 = "T0112",
  T0113 = "T0113",
  T0114 = "T0114",
  T0115 = "T0115",
  T0116 = "T0116",
  T0117 = "T0117",
  T0118 = "T0118",
  T0119 = "T0119",
  T0120 = "T0120",
  T0121 = "T0121",
}

export enum AccountPayableTasks {
  T0201 = "T0201",
  T0202 = "T0202",
  T0203 = "T0203",
  T0204 = "T0204",
  T0205 = "T0205",
  T0206 = "T0206",
  T0207 = "T0207",
  T0208 = "T0208",
  T0209 = "T0209",
  T0210 = "T0210",
  T0211 = "T0211",
  T0212 = "T0212",
  T0213 = "T0213",
  T0225 = "T0225",
  T0226 = "T0226",
  T0227 = "T0227",
}

export enum CorporateCardManagementTasks {
  T0301 = "T0301",
  T0302 = "T0302",
  T0303 = "T0303",
  T0304 = "T0304",
  T0305 = "T0305",
  T0306 = "T0306",
  T0307 = "T0307",
}

export enum OtherAccountAndReconciliationTasks {
  T0401 = "T0401",
  T0402 = "T0402",
  T0403 = "T0403",
  T0404 = "T0404",
  T0405 = "T0405",
  T0406 = "T0406",
  T0407 = "T0407",
  T0408 = "T0408",
  T0409 = "T0409",
  T0410 = "T0410",
  T0411 = "T0411",
  T0412 = "T0412",
  T0413 = "T0413",
  T0414 = "T0414",
  T0415 = "T0415",
  T0416 = "T0416",
  T0417 = "T0417",
  T0418 = "T0418",
  T0419 = "T0419",
  T0420 = "T0420",
  T0421 = "T0421",
  T0422 = "T0422",
  T0423 = "T0423",
  T0424 = "T0424",
  T0425 = "T0425",
  T0426 = "T0426",
  T0427 = "T0427",
  T0428 = "T0428",
  T0438 = "T0438",
  T0439 = "T0439",
  T0440 = "T0440",
  T0441 = "T0441",
  T0442 = "T0442",
}

export enum PeriodReportingTasks {
  T0501 = "T0501",
  T0502 = "T0502",
  T0503 = "T0503",
  T0504 = "T0504",
  T0505 = "T0505",
  T0506 = "T0506",
  T0507 = "T0507",
  T0508 = "T0508",
  T0509 = "T0509",
  T0510 = "T0510",
  T0511 = "T0511",
  T0512 = "T0512",
  T0513 = "T0513",
  T0514 = "T0514",
  T0515 = "T0515",
  T0516 = "T0516",
  T0517 = "T0517",
  T0518 = "T0518",
  T0519 = "T0519",
  T0520 = "T0520",
  T0521 = "T0521",
  T0522 = "T0522",
  T0523 = "T0523",
  T0532 = "T0532",
  T0533 = "T0533",
}

export enum InterimListedCompaniesTasks {
  T0559 = "T0559",
  T0560 = "T0560",
  T0561 = "T0561",
  T0562 = "T0562",
  T0563 = "T0563",
  T0564 = "T0564",
  T0565 = "T0565",
  T0566 = "T0566",
  T0567 = "T0567",
}

export enum AnnualReportingTasks {
  T0601 = "T0601",
  T0602 = "T0602",
  T0603 = "T0603",
  T0604 = "T0604",
  T0605 = "T0605",
  T0606 = "T0606",
  T0607 = "T0607",
  T0608 = "T0608",
  T0609 = "T0609",
  T0610 = "T0610",
  T0611 = "T0611",
  T0612 = "T0612",
  T0613 = "T0613",
  T0614 = "T0614",
  T0615 = "T0615",
  T0616 = "T0616",
  T0617 = "T0617",
  T0618 = "T0618",
  T0619 = "T0619",
  T0620 = "T0620",
  T0621 = "T0621",
  T0622 = "T0622",
  T0623 = "T0623",
  T0624 = "T0624",
  T0625 = "T0625",
  T0626 = "T0626",
  T0627 = "T0627",
  T0628 = "T0628",
  T0629 = "T0629",
  T0630 = "T0630",
  T0631 = "T0631",
  T0632 = "T0632",
  T0633 = "T0633",
  T0634 = "T0634",
  T0635 = "T0635",
  T0636 = "T0636",
  T0637 = "T0637",
  T0638 = "T0638",
  T0639 = "T0639",
  T0640 = "T0640",
  T0641 = "T0641",
  T0642 = "T0642",
  T0643 = "T0643",
  T0644 = "T0644",
  T0645 = "T0645",
  T0646 = "T0646",
  T0647 = "T0647",
  T0648 = "T0648",
  T0649 = "T0649",
  T0650 = "T0650",
  T0651 = "T0651",
  T0652 = "T0652",
  T0653 = "T0653",
  T0654 = "T0654",
  T0655 = "T0655",
  T0656 = "T0656",
  T0657 = "T0657",
  T0658 = "T0658",
  T0659 = "T0659",
  T0660 = "T0660",
}

export enum AnnualReportingListedCompaniesTasks {
  T6607 = "T6607",
  T6608 = "T6608",
  T6609 = "T6609",
  T6610 = "T6610",
  T6611 = "T6611",
  T6612 = "T6612",
  T6613 = "T6613",
  T6614 = "T6614",
  T6615 = "T6615",
  T6616 = "T6616",
  T6617 = "T6617",
}

export enum PayrollAndExpenseAndTravelInvoiceManagementTasks {
  T0701 = "T0701",
  T0702 = "T0702",
  T0703 = "T0703",
  T0704 = "T0704",
  T0705 = "T0705",
  T0706 = "T0706",
  T0707 = "T0707",
  T0708 = "T0708",
  T0709 = "T0709",
  T0710 = "T0710",
  T0711 = "T0711",
  T0712 = "T0712",
  T0713 = "T0713",
  T0714 = "T0714",
  T0715 = "T0715",
  T0716 = "T0716",
  T0717 = "T0717",
  T0718 = "T0718",
  T0719 = "T0719",
  T0720 = "T0720",
  T0721 = "T0721",
  T0722 = "T0722",
  T0723 = "T0723",
  T0724 = "T0724",
  T0725 = "T0725",
  T0726 = "T0726",
  T0727 = "T0727",
  T0728 = "T0728",
  T0729 = "T0729",
  T0730 = "T0730",
  T0731 = "T0731",
  T0732 = "T0732",
  T0733 = "T0733",
  T0734 = "T0734",
  T0735 = "T0735",
  T0736 = "T0736",
  T0737 = "T0737",
  T0738 = "T0738",
  T0739 = "T0739",
  T0740 = "T0740",
  T0741 = "T0741",
}
export enum AnnualPayrollRoutinesTasks {
  T0742 = "T0742",
  T0743 = "T0743",
  T0744 = "T0744",
  T0745 = "T0745",
  T0746 = "T0746",
  T0747 = "T0747",
  T0748 = "T0748",
  T0749 = "T0749",
  T0750 = "T0750",
}

export enum StartUpAccountingTasks {
  T0807 = "T0807",
  T0808 = "T0808",
  T0809 = "T0809",
  T0810 = "T0810",
  T0811 = "T0811",
  T0812 = "T0812",
  T0813 = "T0813",
  T0814 = "T0814",
  T0815 = "T0815",
  T0816 = "T0816",
  T0817 = "T0817",
  T0818 = "T0818",
  T0819 = "T0819",
  T0820 = "T0820",
  T0821 = "T0821",
  T0822 = "T0822",
  T0823 = "T0823",
  T0824 = "T0824",
}
export enum YearlyInternalDocumentationAndFormalitiesTasks {
  T1005 = "T1005",
  T1006 = "T1006",
  T1007 = "T1007",
  T1008 = "T1008",
  T1009 = "T1009",
  T1010 = "T1010",
  T1011 = "T1011",
  T1012 = "T1012",
  T1013 = "T1013",
}

export enum AccountReceivableAdditionalCosts {
  T0122 = "T0122",
  T0123 = "T0123",
  T0124 = "T0124",
}

export enum AccountPayableAdditionalCosts {
  T0214 = "T0214",
  T0215 = "T0215",
  T0216 = "T0216",
}

export enum CorporateCardManagementAdditionalCosts {
  T0308 = "T0308",
  T0309 = "T0309",
}

export enum OtherAccountAndReconciliationAdditionalCosts {
  T0429 = "T0429",
}

export enum PeriodReportingAdditionalCosts {
  T0524 = "T0524",
}

export enum AnnualReportingAdditionalCosts {
  T0661 = "T0661",
  T0662 = "T0662",
  T0663 = "T0663",
  T0664 = "T0664",
  T0665 = "T0665",
  T0666 = "T0666",
  T0667 = "T0667",
  T0668 = "T0668",
  T0669 = "T0669",
  T0670 = "T0670",
  T0671 = "T0671",
  T0672 = "T0672",
  T0673 = "T0673",
  T0674 = "T0674",
  T0675 = "T0675",
  T0676 = "T0676",
  T0677 = "T0677",
  T0678 = "T0678",
  T0679 = "T0679",
  T0680 = "T0680",
  T0681 = "T0681",
  T0682 = "T0682",
  T0683 = "T0683",
  T0684 = "T0684",
  T0685 = "T0685",
  T0686 = "T0686",
  T0687 = "T0687",
}

export enum PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts {
  T0751 = "T0751",
  T0752 = "T0752",
  T0753 = "T0753",
  T0754 = "T0754",
  T0755 = "T0755",
  T0757 = "T0757",
  T0774 = "T0774",
}

export type QuestionCode =
  | GeneralInformationQuestion
  | AccountPayableQuestion
  | AccountReceivableQuestion
  | CorporateCardManagementQuestion
  | OtherAccountAndReconciliationQuestions
  | PeriodReportingQuestion
  | InterimListedCompaniesQuestions
  | AnnualReportingQuestions
  | AnnualReportingListedCompaniesQuestions
  | PayrollAndExpenseAndTravelInvoiceManagementQuestions
  | StartUpAccountingQuestions
  | StartUpPayrollQuestions;

export type ManuallyAddedTaskCode = string;

export type AutomaticTaskCode =
  | AccountReceivableTasks
  | AccountPayableTasks
  | CorporateCardManagementTasks
  | OtherAccountAndReconciliationTasks
  | PeriodReportingTasks
  | InterimListedCompaniesTasks
  | AnnualReportingTasks
  | PayrollAndExpenseAndTravelInvoiceManagementTasks
  | AnnualPayrollRoutinesTasks
  | StartUpAccountingTasks
  | YearlyInternalDocumentationAndFormalitiesTasks;

export type TaskCode = AutomaticTaskCode | ManuallyAddedTaskCode;

export type AdditionalCostConfigurationCode =
  | AccountReceivableAdditionalCosts
  | AccountPayableAdditionalCosts
  | CorporateCardManagementAdditionalCosts
  | OtherAccountAndReconciliationAdditionalCosts
  | PeriodReportingAdditionalCosts
  | AnnualReportingAdditionalCosts
  | PayrollAndExpenseAndTravelInvoiceManagementAdditionalCosts;

export enum CalculationVariable {
  Amount = "amount",
  ContactPerson = "contactPerson",
  TotalWorkCost = "totalWorkCost",
}

export enum Routes {
  Home = "",
  Logout = "log-out",
  Question = "questions",
  PriceAndTimeCalculation = "price-and-time-calculation",
  EngagementDescription = "engagement-description",
  GenerateDocuments = "generate-documents",
  EngagementLettersSummaryView = "engagement-letters-summary-view",
}

export enum QueryParams {
  businessOpportunityId = "upsalesbusinessoportunityid",
  clientId = "upsalescustomerid",
  unit = "unit",
  engagementConfigurationId = "engagementConfigurationId",
}

export enum CustomDataGridColumnId {
  TotalWorkCost = "TOTAL_WORK_COST",
  AdjustmentOfCost = "ADJUSTMENT_OF_COST",
  TotalCost = "TOTAL_COST",
  AdditionalCostsTotalCost = "ADDITIONAL_COSTS_TOTAL_COST",
  T0774Row = "T_0774_ROW",
  PriceListCostRow = "PRICE_LIST_COST_ROW",
  SumRow = "SUM_ROW",
  RecurringServicesTotal = "RECURRING_SERVICES_TOTAL",
  PayrollServicesTotal = "PAYROLL_SERVICES_TOTAL",
}

export enum Currency {
  kr = "kr",
}

export const USED_CURRENCY = Currency.kr;

export const INPUT_CHANGE_DEBOUNCE_MILLIS = 300;

export const RecurringTypeMultiplierMap: {
  [recurringType in ServiceRecurringType]: number;
} = {
  [ServiceRecurringType.Monthly]: 12,
  [ServiceRecurringType.OneTime]: 1,
  [ServiceRecurringType.Quarterly]: 4,
  [ServiceRecurringType.Yearly]: 1,
};

export enum SummaryRowType {
  Allocation = "allocation",
  TotalCost = "totalCost",
}

export enum UpdateStateOnEngagementConfigurationReadScenario {
  ImportConfiguration = "importConfiguration",
  LoadConfiguration = "loadConfiguration",
  Conflict = "conflict",
}

export enum EngagementDocumentType {
  EngagementLetter = "EngagementLetter",
  CounselingLetter = "CounselingLetter",
  AdditionalDocument = "AdditionalDocument",
  Appendix = "Appendix",
  EngagementDescription = "EngagementDescription",
}

export enum GenerateDocumentsDocumentFlow {
  SendFilesToSharepoint = "SendFilesToSharepoint",
  SetFinalVersion = "SetFinalVersion",
}

export enum FileExtension {
  Docx = "docx",
  Pdf = "pdf",
}

// scroll restorer
export const SCROLL_RESTORER_DEBOUNCE_TIME = 300;
export const SCROLL_RESTORER_SESSION_STORAGE_PREFIX = `scrollRestoration-`;
export const SCROLL_RESTORER_SCROLL_INTERACT_DEBOUNCE_TIME = 700;

export const LOCAL_STORAGE_FORCE_REDIRECT_KEY = "FORCE_REDIRECT";
export const LOCAL_STORAGE_DOCUMENT_FLOW_KEY = "DOCUMENT_FLOW";

export const SNACKBAR_AUTOCLOSE_TIME_MILLIS = 6000;
export const ERROR_MESSAGE_VISIBILITY_MILLIS = 8000;

export const MEGABYTES_IN_BYTES_25 = 25 * 1024 * 1024;

export const ADDITIONAL_DOCUMENTS_LIMIT_NO = 10;

import { useReducer, createContext } from "react";
import { servicesConfiguration } from "../core/Configurations/Services.config";
import { formServiceDTOs } from "../utils/dto";

import { appReducer } from "../core/Context";
import { CoreInterfaces, DTOs } from "../core/Models";
import { applyUpdatesOnQuestionChange } from "../utils/processors/Questions";
import * as Constants from "../core/Constants";
import * as StateProcessors from "../core/Context/state-processors";
import { createGeneralVariables } from "../utils";

const initialAppState: CoreInterfaces.AppState = {
  generalVariables: createGeneralVariables(),
  services: [],
  currentConfiguration: {
    id: null,
    engagementLetterParentId: null,
    version: 0,
    businessUnit: null,
    lastModifiedDate: null,
    organisationNumber: null,
    companyName: null,
    status: null,
    description: null,
    applicationConfiguration: null,
    createdBy: null,
    createdDate: null,
    signingDate: null,
    signingStatus: null,
    lastAccessedTime: null,
    consent: null,
    digitalSigningDetails: null,
    totalCost: null,
    totalCostManualOverride: null,
    totalWorkingHours: null,
    crmRowId: null,
    validFromDate: null,
    engagementLetterMetadata: null,
    owner: null,
    validUntilDate: null,
    isHiddenFromImporting: false,
    engagementDocumentsLanguage: null,
    lastUserChangesDate: null,
  },
  remoteData: {
    customerContactPersons: [],
    gtContactPersons: [],
    companyDetails: null,
    businessOpportunityInfo: null,
    systemAdministrationMessages: [],
    employeesOffices: [],
    userRoles: [],
    officeManagers: [],
  },
  state: {
    isPageBlurred: false,
    isRatesChangeMessagesShow: false,
  },
  taskAllocations: [],
  taskCosts: [],
  offices: [],
  authProviderUserDetails: null,
  language: Constants.Languages.SE,
  areUnsavedChanges: false,
  unsavedChangesTimestamp: null,
  applicationConfCurrentVersions: {
    rateCart: null,
    taskCost: null,
    taskAllocation: null,
  },
  showConflictUpdateDialog: false,
  isConflictOnUpdateEngagement: false,
  lastModifiedBy: null,
};

const serviceHandlingFunction = (service: DTOs.ServiceDTO) => {
  return applyUpdatesOnQuestionChange(initialAppState, service);
};

const services = formServiceDTOs(
  servicesConfiguration,
  serviceHandlingFunction
);

const generalInformation = services.find(
  (service) => service.data.code === Constants.ServiceCode.GeneralInformation
);

const generalVariables =
  StateProcessors.createGeneralVariablesObject(generalInformation);
generalInformation.data.questions.forEach((eachGeneralQuestion) => {
  StateProcessors.updateServicesOnGeneralQuestionChange(
    services,
    eachGeneralQuestion,
    generalVariables
  );
});

initialAppState.services = services;
initialAppState.generalVariables = generalVariables;

export const AppContext = createContext<{
  globalState: CoreInterfaces.AppState;
  dispatch: Function;
}>({
  globalState: initialAppState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

export function AppContextProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [globalState, dispatch] = useReducer(appReducer, initialAppState);

  const providerValue = {
    globalState,
    dispatch,
  };

  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
}
